import CoinFlipImg from "../../assets/img/coinflip-icon.png";
import DiceGameImg from "../../assets/img/dice-icon.png";
import MinesGameImg from "../../assets/img/miner-icon.png";
import PlinkoImg from "../../assets/img/plinko.png";
import PokerImg from "../../assets/img/poker.png";
import RPSGameImg from "../../assets/img/rock-paper-scissors.png";
import SeparatorImg from "../../assets/img/separador.png";
import SlotsImg from "../../assets/img/slots-icon.png";
import ComingSoonImg from "../../assets/img/token.png";
import Game from "./game/Game";

import "./Games.scss";

function Games() {
  return (
    <div className="container" id="games">
      <div className="row pt-50">
        <div className="col-lg-12">
          <div className="title-container">
            <span className="subtitle">TRY OUR </span>
            <span className="subtitle secondary">GAMES!</span>
          </div>
        </div>
        <div className="col-lg-12 justify-center">
          <img src={SeparatorImg} className="separator" alt="separator" />
        </div>
        <div className="col-lg-12 justify-center pt-20">
          <span className="description">
            We have a good variety of decentralised games that you can play
            right now without any sign-up. Start earning now!
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 pt-40">
          <Game gameImg={DiceGameImg} gameName="DICE" colorSchema="color-one" />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 pt-40">
          <Game
            gameImg={CoinFlipImg}
            gameName="COIN FLIP"
            colorSchema="color-two"
          />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 pt-40">
          <Game
            gameImg={PlinkoImg}
            gameName="PLINKO"
            colorSchema="color-five"
          />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 pt-40">
          <Game gameImg={SlotsImg} gameName="SLOTS" colorSchema="color-six" />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 pt-40">
          <Game gameImg={RPSGameImg} gameName="RPS" colorSchema="color-four" />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 pt-40">
          <Game
            gameImg={PokerImg}
            gameName="VIDEO POKER"
            colorSchema="color-three"
          />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 pt-40">
          <Game
            gameImg={MinesGameImg}
            gameName="MINES"
            colorSchema="color-two"
          />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 pt-40">
          <Game
            gameImg={ComingSoonImg}
            gameName="COMING SOON"
            colorSchema="color-one"
          />
        </div>
      </div>
    </div>
  );
}

export default Games;
