import BowlPepinoImg from "../../assets/img/bowl-pepino-icon.png";
import DecentralizedImg from "../../assets/img/decentralized.svg";
import WalletImg from "../../assets/img/earn-passive.svg";
import PepinoHeader from "../../assets/img/pepino-glass-icon.png";
import FairImg from "../../assets/img/provably-fair.svg";
import SeparatorImg from "../../assets/img/separador.png";
import UpImg from "../../assets/img/up-icon.png";
import CardImg from "../../assets/img/wallet-icon.png";
import AppImg from "../../assets/img/app.png";
import CheckImg from "../../assets/img/checkbox.svg";
import { useMediaQuery } from "@uidotdev/usehooks";

import "./Home.scss";

function Home() {
  const isLargeDevice = useMediaQuery("only screen and (min-width : 993px)");

  return (
    <div className="container" id="home">
      <div className="row pt-50">
        <div className="col-lg-12 justify-center pb-10">
          <img src={PepinoHeader} className="pepino-header" alt="pepi" />
        </div>
        <div className="col-lg-12">
          <div className="title-container">
            <span className="title">PLAY AT THE BEST CRYPTO</span>
            <br />
            <span className="title secondary">CASINO ONLINE!</span>
          </div>
        </div>
        <div className="col-lg-12 justify-center">
          <img src={SeparatorImg} className="separator" alt="separator" />
        </div>
        <div className="col-lg-12 justify-center pt-20">
          <span className="description">
            Pepino Casino is a Casino with the Best Games such as Craps, Poker
            and many others.
          </span>
        </div>
        <div className="col-lg-12 justify-center pt-30">
          <a
            href="https://testnet.pepino.casino/"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-start">START EARNING</button>
          </a>
        </div>
      </div>

      <div className="row justify-center">
        <div className="col-lg-8">
          <div className="row">
            <div className="col-lg-4 pt-40">
              <div className="row align-center">
                <div className="col-lg-4 justify-center pt-10">
                  <img
                    src={DecentralizedImg}
                    className="check-img"
                    alt="check"
                  />
                </div>
                <div className="col-lg-6 text-center pt-10">
                  <span className="check-txt">Decentralised</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 pt-40">
              <div
                className={
                  "row align-center " + (isLargeDevice ? "check-border" : "")
                }
              >
                <div className="col-lg-4 justify-center pt-10">
                  <img src={FairImg} className="check-img" alt="check" />
                </div>
                <div className="col-lg-6 text-center pt-10">
                  <span className="check-txt">Provably Fair</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 pt-40">
              <div className="row align-center">
                <div className="col-lg-4 justify-center pt-10">
                  <img src={WalletImg} className="check-img" alt="check" />
                </div>
                <div className="col-lg-8 text-center pt-10">
                  <span className="check-txt">Earn Passive Income</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-center">
        <div className="col-12 col-md-10 col-lg-12 col-xl-10">
          <div className="row">
            <div className="col-lg-4 pt-50">
              <div className="feature-box">
                <div className="row">
                  <div className="col-lg-12 justify-center">
                    <img
                      src={BowlPepinoImg}
                      className="box-image"
                      alt="bowl pepino"
                    />
                  </div>
                  <div className="col-lg-12 pt-20">
                    <span className="title justify-center">Decentralised</span>
                  </div>
                  <div className="col-lg-12 pt-10 text-center">
                    <span className="subtitle">
                      Fully onchain, all the games are public and verifiable
                      smart contracts without a single point of failure. No
                      signups, no KYC, nothing.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 pt-50">
              <div className="feature-box">
                <div className="row">
                  <div className="col-lg-12 justify-center">
                    <img src={UpImg} className="box-image" alt="bowl pepino" />
                  </div>
                  <div className="col-lg-12 pt-20">
                    <span className="title justify-center">Provably Fair</span>
                  </div>
                  <div className="col-lg-12 pt-10 text-center">
                    <span className="subtitle">
                      All our random numbers are generated by{" "}
                      <a
                        className="underline"
                        href="https://docs.gelato.network/web3-services/vrf"
                      >
                        Gelato VRF
                      </a>{" "}
                      making all game results totally public and provably fair.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 pt-50">
              <div className="feature-box">
                <div className="row">
                  <div className="col-lg-12 justify-center">
                    <img
                      src={CardImg}
                      className="box-image"
                      alt="bowl pepino"
                    />
                  </div>
                  <div className="col-lg-12 pt-20">
                    <span className="title justify-center">Passive Yield</span>
                  </div>
                  <div className="col-lg-12 pt-10 text-center">
                    <span className="subtitle">
                      The community is the bank. Stake your assets to provide
                      liquidity for the games. Earn passive income while you
                      sleep.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row pt-80" id="about">
        <div className="col-lg-12">
          <div className="title-container">
            <span className="title">DISCOVER OUR </span>
            <span className="title secondary">APP!</span>
          </div>
        </div>
        <div className="col-lg-12 justify-center">
          <img src={SeparatorImg} className="separator" alt="separator" />
        </div>
        <div className="col-lg-12 justify-center pt-20">
          <span className="description">
            Have fun with our decentralized games and grow your tokens with our
            pools in our easy-to-use interface!
          </span>
        </div>
        <div className="col-lg-12 justify-center pt-50">
          <img src={AppImg} className="pepino-app" alt="pepi" />
        </div>
      </div>

      <div className="row justify-center pb-20">
        <div className="col-lg-11">
          <div className="row justify-center">
            <div className="col-lg-3 pt-40">
              <div className="row align-center">
                <div className="col-lg-4 justify-center pt-10">
                  <img src={CheckImg} className="check-img" alt="check" />
                </div>
                <div className="col-lg-6 text-center pt-10">
                  <span className="check-txt">Decentralised</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 pt-40">
              <div className="row align-center">
                <div className="col-lg-4 justify-center pt-10">
                  <img src={CheckImg} className="check-img" alt="check" />
                </div>
                <div className="col-lg-6 text-center pt-10">
                  <span className="check-txt">Legitimate</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 pt-40">
              <div className="row align-center">
                <div className="col-lg-4 justify-center pt-10">
                  <img src={CheckImg} className="check-img" alt="check" />
                </div>
                <div className="col-lg-8 text-center pt-10">
                  <span className="check-txt">Secure</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 pt-40">
              <div className="row align-center">
                <div className="col-lg-4 justify-center pt-10">
                  <img src={CheckImg} className="check-img" alt="check" />
                </div>
                <div className="col-lg-8 text-center pt-10">
                  <span className="check-txt">Immersive</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 justify-center pt-40">
          <a
            href="https://testnet.pepino.casino/"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-start">GO TO CASINO!</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;
