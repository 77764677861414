import EthImg from "../../assets/img/ethereum-logo.svg";
import PolygonImg from "../../assets/img/polygon-logo.svg";
import SeparatorImg from "../../assets/img/separador.png";
import PepinoBannerImg from "../../assets/img/pepino3.png";

import Pool from "./pool/Pool";

import "./Pools.scss";

function Pools() {
  return (
    <div className="container" id="earn">
      <div className="row pt-50">
        <div className="col-lg-12">
          <div className="title-container">
            <span className="subtitle">OUR POOLS</span>
          </div>
        </div>
        <div className="col-lg-12 justify-center">
          <img src={SeparatorImg} className="separator" alt="separator" />
        </div>
        <div className="col-lg-12 justify-center pt-20">
          <span className="description text-center">
            You are the bank! Provide liquidity that will be used for the games.
            As the bank balance increases you're staked balance will increase
            proportionally. <br />
            Withdraw whenever you want.
          </span>
        </div>
      </div>

      <div className="row justify-center">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 pt-40">
          <Pool
            poolImg={PolygonImg}
            poolName="Matic"
            poolDesc="By staking Matic you are providing liquidity to the bank, your staked balance will fluctuate according to the game wins/losses."
          />
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 pt-40">
          <Pool
            poolImg={EthImg}
            poolName="Ethereum"
            poolDesc="By staking Ethereum you are providing liquidity to the bank, your staked balance will fluctuate according to the game wins/losses."
          />
        </div>
      </div>

      <div className="pt-80" id="stake">
        <div className="banner-container">
          <div className="banner-img-box">
            <img
              src={PepinoBannerImg}
              className="banner-img"
              alt="Pepino Banner"
            />
          </div>

          <div className="banner-txt d-none d-lg-block">
            <div className="row">
              <div className="col-5"></div>
              <div className="col-7">
                <div className="row">
                  <div className="col-lg-12">
                    <span className="banner-title">Let's get started?</span>
                  </div>
                  <div className="col-lg-10 pt-10">
                    <span className="banner-description">
                      Holders within the ecosystem collaboratively engage in
                      discussions, create and participate in proposals processes
                      to determine protocol upgrades. PEPI holders will also
                      receive rewards from the fees generated in the games.
                    </span>
                  </div>
                  <div className="col-lg-7 pt-30">
                    <a
                      href="https://testnet.pepino.casino/#/pools"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="btn btn-banner">Stake Now</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pools;
