import GithubIcon from "../../assets/img/git.svg";
import Logo from "../../assets/img/logo.png";
import TelegramIcon from "../../assets/img/telegram.svg";
import TwitterIcon from "../../assets/img/x-social-media.svg";
import SeparatorImg from "../../assets/img/separador.png";

import "./Footer.scss";

function Footer() {
  return (
    <>
      <div className="container-fluid p-0">
        <div className="footer-banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="row pt-80">
                  <div className="col-12">
                    <div className="title-container">
                      <span className="subtitle">
                        ARE YOU READY <br /> TO ROCK?
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row justify-center pt-10">
                  <div className="col-12 justify-center">
                    <img
                      src={SeparatorImg}
                      className="separator"
                      alt="separator"
                    />
                  </div>
                  <div className="row justify-center pt-30">
                    <div className="col-12 col-md-8 justify-center">
                      <a
                        href="https://testnet.pepino.casino/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="btn btn-footer">
                          START WINNING
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 pt-40 d-none d-lg-block">
                <div className="footer-img"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid p-0">
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 justify-center align-center pt-30">
                <img className="footer-logo" src={Logo} alt="" />
              </div>
              <div className="col-lg-6 justify-center align-center pt-30">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-12 pt-10 justify-center">
                        <a href="#" id="home">
                          <span className="footer-subsection">HOME</span>
                        </a>
                      </div>
                      <div className="col-lg-12 pt-10 justify-center">
                        <a href="#" id="about">
                          <span className="footer-subsection">ABOUT US</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-12 pt-10 justify-center">
                        <a href="#" id="games">
                          <span className="footer-subsection">GAMES</span>
                        </a>
                      </div>
                      <div className="col-lg-12 pt-10 justify-center">
                        <a href="#" id="earn">
                          <span className="footer-subsection">EARN</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-12 pt-10 justify-center">
                        <a href="#" id="stake">
                          <span className="footer-subsection">STAKING</span>
                        </a>
                      </div>
                      <div className="col-lg-12 pt-10 justify-center">
                        <a href="#" id="dao">
                          <span className="footer-subsection">DAO</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 justify-center align-center pt-30">
                <div className="row">
                  <div className="col-3">
                    <div className="rrss-container justify-center align-center">
                      <a
                        target="_blank"
                        href="https://t.me/pepino_casino"
                        rel="noreferrer"
                      >
                        <img
                          className="rrss-icon"
                          src={TelegramIcon}
                          alt="RRSS icon"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="rrss-container justify-center align-center">
                      <a
                        target="_blank"
                        href="https://twitter.com/pepino_casino"
                        rel="noreferrer"
                      >
                        <img
                          className="rrss-icon"
                          src={TwitterIcon}
                          alt="RRSS icon"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="rrss-container justify-center align-center">
                      <a
                        target="_blank"
                        href="https://github.com/pepino-casino"
                        rel="noreferrer"
                      >
                        <img
                          className="rrss-icon"
                          src={GithubIcon}
                          alt="RRSS icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row pt-40 pb-20">
              <div className="col-lg-12 justify-center">
                <span className="copyright">
                  © 2024 Pepino Casino. All rights reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
