import SeparatorImg from "../../assets/img/separador.png";

import "./Dao.scss";

function Dao() {
  return (
    <div className="container" id="dao">
      <div className="row pt-20">
        <div className="col-lg-12">
          <div className="title-container">
            <span className="subtitle">DAO</span>
          </div>
        </div>
        <div className="col-lg-12 justify-center">
          <img src={SeparatorImg} className="separator" alt="separator" />
        </div>
        <div className="col-lg-12 justify-center pt-20">
          <span className="description">
            Check our latest improvement proposals, vote for your favorite ones,
            propose new changes, claim your passive rewards and much more!
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-12 pt-40">
          <div className="soon-box grid-content-center">
            <div className="row">
              <div className="col-lg-12 justify-center">
                <span className="title">Coming Soon</span>
              </div>
            </div>
            <div className="row pt-30 justify-center">
              <div className="col-10 col-md-5 col-lg-4 justify-center">
                <button
                  onClick={() => alert("Coming Soon!")}
                  className="btn btn-dao"
                >
                  DAO
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dao;
