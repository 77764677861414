import "./Game.scss";

interface GameProps {
  gameImg: string;
  gameName: string;
  colorSchema:
    | "color-one"
    | "color-two"
    | "color-three"
    | "color-four"
    | "color-five"
    | "color-six";
}

function Game({ gameImg, gameName, colorSchema }: GameProps) {
  return (
    <div className={"game-container " + colorSchema}>
      <div className="row">
        <div className="col-lg-12 justify-center">
          <img className="game-img" src={gameImg} alt="Pepino Game" />
        </div>
      </div>
      <div className="row pt-30">
        <div className="col-lg-12 justify-center">
          <span className="game-title">{gameName}</span>
        </div>
      </div>
    </div>
  );
}

export default Game;
