import { useMediaQuery } from "@uidotdev/usehooks";

import "./Pool.scss";

interface PoolProps {
  poolImg: string;
  poolName: string;
  poolDesc: string;
}

function Pool({ poolImg, poolName, poolDesc }: PoolProps) {
  const isLargeDevice = useMediaQuery("only screen and (min-width : 993px)");

  return (
    <div className="pool-container">
      <div className="row">
        <div className="col-lg-12 justify-center">
          <img className="pool-img" src={poolImg} alt="Pepino Game" />
        </div>
      </div>
      <div className="row pt-20">
        <div className="col-lg-12 justify-center">
          <span className="pool-title">{poolName}</span>
        </div>
      </div>
      <div className="row pt-10">
        <div className="col-lg-12 justify-center">
          <span className="pool-desc text-center">{poolDesc}</span>
        </div>
      </div>

      <div className="row pt-10 pt-20">
        <div className={"col-lg-6 " + (isLargeDevice ? "pool-border" : "")}>
          <div className="row">
            <div className="col-lg-12 text-center">
              <span className="apr-box-data">3,6%</span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-center">
              <span className="apr-box-title">APR</span>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-12 text-center">
              <span className="pool-box-data">$3,102,007</span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-center">
              <span className="pool-box-title">Staked</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 justify-center pt-30">
          <a
            href="https://testnet.pepino.casino/#/pools"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-pool">Stake Now</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Pool;
