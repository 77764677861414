import Dao from "../dao/Dao";
import Footer from "../footer/Footer";
import Games from "../games/Games";
import Home from "../home/Home";
import Navbar from "../navbar/Navbar";
import Staking from "../pools/Pools";

import "./Layout.scss";

function Layout() {
  return (
    <>
      <section className="first-section">
        <Navbar />
        <section className="pepino-background">
          <Home />
          <Games />
        </section>

        <section className="pt-40">
          <Staking />
        </section>

        <section className="pt-40">
          <Dao />
        </section>

        <section className="pt-80">
          <Footer />
        </section>
      </section>
    </>
  );
}

export default Layout;
