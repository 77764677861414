import Logo from "../../assets/img/logo.png";
import TelegramLogo from "../../assets/img/telegram.svg";
import "./Navbar.scss";

function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <a href="/">
          <img className="main-logo" src={Logo} alt="logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarToggler"
          aria-controls="navbarToggler"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarToggler">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link" href="#home">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about">
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#games">
                Games
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#earn">
                Earn
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#stake">
                Staking
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#dao">
                DAO
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://docs.pepino.casino/pepino-casino/"
                target="_blank"
                rel="noreferrer"
              >
                Docs
              </a>
            </li>
          </ul>

          <div className="plr-10 ms-auto">
            <button className="btn btn-telegram">
              <a
                target="_blank"
                href="https://t.me/pepino_casino"
                rel="noreferrer"
              >
                <img
                  src={TelegramLogo}
                  className="telegram-logo"
                  alt="telegram"
                />
              </a>
            </button>
          </div>
          <div className="pt-20 d-block d-lg-none"></div>
          <div>
            <a
              href="https://testnet.pepino.casino/"
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn btn-app">Launch Testnet</button>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
